<template>
  <div>
    <p class="my-8 text--lighttext">
      Use this form to add participating restaurants to
      {{ settings.organizationName }}.
    </p>
    <div v-for="(newRestaurant, i) in newRestaurants" :key="i">
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="newRestaurant.restaurantName"
            label="Restaurant Name"
            outlined
            :disabled="newRestaurant.disabled"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="newRestaurant.adminName"
            label="Admin Name"
            outlined
            :disabled="newRestaurant.disabled"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="newRestaurant.adminEmail"
            label="Admin Email"
            outlined
            :disabled="newRestaurant.disabled"
          />
        </v-col>
        <v-col
          v-if="i < newRestaurants.length - 1"
          cols="1"
          justify="center"
          align="center"
        >
          <v-btn v-if="!newRestaurant.disabled" icon @click="removeRestaurantRow(i)">
            <v-icon>mdi-minus-circle-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <onboarding-footer :next="isValid" @submit="submitData" />
  </div>
</template>

<script>
import OnboardingFooter from "../OnboardingFooter.vue"
import { mapGetters, mapActions, mapState } from "vuex"
import updateUsers from "@/mixins/updateUsers"
import topLevelMixins from "@/AuthenticatedContent/mixins.js"

export default {
  name: "onboarding-restaurants-admin",
  components: { OnboardingFooter },
  mixins: [updateUsers, topLevelMixins],
  data() {
    return {
      newRestaurants: [],
      isValid: true,
    }
  },
  computed: {
    ...mapState(["settings", "users", "currentUserRole", "restaurants"]),
    ...mapGetters(["currentOnboarding"]),
    component() {
      return this.settings.singleBrandEnabled || this.currentUserRole === "Manager"
        ? "Restaurants"
        : "Admin"
    },
  },
  watch: {
    newRestaurants: {
      handler() {
        // Auto-insert a new row if the last row is filled out. Delete the last row if there are two empty last rows.
        const isEmpty = rowIndex => {
          return (
            this.newRestaurants[rowIndex] &&
            this.newRestaurants[rowIndex].restaurantName?.length == 0 &&
            this.newRestaurants[rowIndex].adminName?.length == 0 &&
            this.newRestaurants[rowIndex].adminEmail?.length == 0
          )
        }
        if (
          isEmpty(this.newRestaurants.length - 1) &&
          isEmpty(this.newRestaurants.length - 2)
        ) {
          this.newRestaurants.splice(this.newRestaurants.length - 1, 1)
        } else if (!isEmpty(this.newRestaurants.length - 1)) {
          this.newRestaurants.push({
            restaurantName: "",
            adminName: "",
            adminEmail: "",
          })
        }

        this.isValid = this.newRestaurants.every(restaurant => {
          return (
            restaurant.disabled ||
            (restaurant.restaurantName &&
              restaurant.adminName &&
              restaurant.adminEmail) ||
            (restaurant.restaurantName?.length == 0 &&
              restaurant.adminName?.length == 0 &&
              restaurant.adminEmail?.length == 0)
          )
        })
      },
      deep: true,
    },
  },
  mounted() {
    if (this.restaurants?.length) {
      this.newRestaurants = this.restaurants.map(restaurant => {
        let adminUser
        if (this.users) {
          adminUser = Object.values(this.users)?.find(
            user =>
              user.restaurantIds.length === 1 &&
              user.restaurantIds.includes(restaurant.id) &&
              user.role === "Manager"
          )
        }
        return {
          restaurantName: restaurant.name,
          adminName: adminUser ? adminUser.name : "",
          adminEmail: adminUser ? adminUser.email : "",
          disabled: true,
        }
      })
    }
    this.newRestaurants.push({
      restaurantName: "",
      adminName: "",
      adminEmail: "",
    })
  },
  methods: {
    ...mapActions(["addRestaurant"]),
    async submitData() {
      for (const newRestaurant of this.newRestaurants.filter(
        r =>
          !r.disabled &&
          r.restaurantName?.length &&
          r.adminName?.length &&
          r.adminEmail?.length &&
          /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(r.adminEmail)
      )) {
        const restaurantId = await this.addRestaurant({
          name: newRestaurant.restaurantName,
          id: null,
          storeNumber: "",
          prepTime: "",
          address: {
            addressLine1: "",
            addressLine2: "",
            city: "",
            country: "",
            latitude: "",
            longitude: "",
            postCode: "",
            provinceState: "",
          },
          hours: {},
          enabledFeatures: {
            delivery: false,
            multiLocation: false,
          },
          images: {
            logo: "",
            hero: "",
          },
          social: {
            type: "",
            handle: "",
            url: "",
          },
          isDeleted: false,
          isEnabled: true,
          socialTypes: [
            { name: "Facebook", id: "facebook" },
            { name: "Twitter", id: "twitter" },
            { name: "Instagram", id: "instagram" },
          ],
        })

        const newUser = {
          name: newRestaurant.adminName,
          email: newRestaurant.adminEmail,
          restaurantIds: [restaurantId],
          role: "Manager",
          password: Math.random().toString(36).slice(2),
        }

        let isExistingUser = false // checks two cases: 1) user exists in access array 2) user exists in firebase
        let userId = ""

        try {
          isExistingUser = Object.keys(this.users).some(
            uid => this.users[uid].email === newRestaurant.adminEmail // 1)
          )
          userId = await this.getUidByEmail(newUser.email) // 2)
          isExistingUser = true
        } catch (error) {
          console.error("Error finding user", error)
        }

        if (isExistingUser) {
          const existingUser = {
            ...newUser,
            restaurantIds: [...this.users[userId]?.restaurantIds, restaurantId],
          }
          await this.updateUsers(userId, true, existingUser) // update user with new restaurant id
        } else {
          this.createUser(newUser, true)
            .then(() => {
              this.clearFields()
              this.$emit("close")
            })
            .catch(error => {
              console.error(error)
            })
            .finally(() => {
              this.closeDisabled = this.awaitingResponse = false
            })
        }
      }
    },
    addRestaurantRow() {
      this.newRestaurants.push({
        restaurantName: "",
        adminName: "",
        adminEmail: "",
      })
    },
    removeRestaurantRow(index) {
      this.newRestaurants.splice(index, 1)
    },
  },
}
</script>
