<template>
  <v-card outlined class="pb-4">
    <v-card-subtitle>Details</v-card-subtitle>
    <v-card-text class="pb-0">
      <v-row class="mt-3">
        <v-col cols="6">
          <v-text-field
            v-model="organizationName"
            label="Name"
            outlined
            hide-details="auto"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="organizationPhoneNumber"
            label="Phone Number"
            outlined
            hide-details="auto"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <template v-if="!organizationAddress">
      <v-card-subtitle class="pb-0 pt-8">Address</v-card-subtitle>
      <v-card-text>
        <address-form
          ref="addressForm"
          v-model="organizationAddressParts"
          :collapsible="false"
          hide-coordinates
          class="mt-6"
        />
      </v-card-text>
    </template>
    <v-card-text v-else>
      <v-text-field
        v-model="organizationAddress"
        label="Address"
        outlined
        hide-details="auto"
      />
    </v-card-text>
    <div v-if="!settings.singleBrandEnabled">
      <v-card-subtitle class="text--lighttext">Hours</v-card-subtitle>
      <v-card-text>
        <weekly-hours v-model="hours" class="mt-6" />
      </v-card-text>
    </div>
    <onboarding-footer :next="isValid" @submit="submitData" />
  </v-card>
</template>

<script>
import OnboardingFooter from "../OnboardingFooter.vue"
import WeeklyHours from "@/AuthenticatedContent/shared/forms/datetime/WeeklyHours.vue"
import AddressForm from "@/AuthenticatedContent/shared/forms/AddressForm.vue"
import topLevelMixins from "@/AuthenticatedContent/mixins.js"
import { mapActions, mapState } from "vuex"

export default {
  name: "onboarding-settings",
  components: { WeeklyHours, AddressForm, OnboardingFooter },
  mixins: [topLevelMixins],
  data() {
    return {
      organizationName: "",
      organizationPhoneNumber: "",
      organizationAddress: "",
      hours: {},
      organizationAddressParts: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        country: "",
        latitude: "",
        longitude: "",
        postCode: "",
        provinceState: "",
      },
    }
  },
  computed: {
    ...mapState(["settings"]),
    addressString() {
      if (this.organizationAddress) {
        return this.organizationAddress
      }
      return this.convertAddressObjectToString(this.organizationAddressParts)
    },
    isValid() {
      return (
        this.organizationName?.length > 0 &&
        this.organizationPhoneNumber?.length > 0 &&
        (this.organizationAddress?.length > 0 ||
          (this.organizationAddressParts.addressLine1?.length > 0 &&
            this.organizationAddressParts.city?.length > 0 &&
            this.organizationAddressParts.provinceState?.length > 0 &&
            this.organizationAddressParts.postCode?.length > 0 &&
            this.organizationAddressParts.country?.length > 0))
      )
    },
  },
  created() {
    this.organizationName = this.settings.organizationName
    this.organizationPhoneNumber = this.settings.organizationPhoneNumber
    this.hours = this.settings.hours
    this.organizationAddress = this.settings.organizationAddress
  },
  methods: {
    ...mapActions(["updatePublicSettings"]),
    async submitData() {
      if (!this.isValid) {
        return
      }
      const payload = {
        organizationAddress: this.addressString,
        organizationName: this.organizationName,
        organizationPhoneNumber: this.organizationPhoneNumber,
        hours: this.hours,
      }
      if (this.settings.singleBrandEnabled) {
        delete payload.hours
      }

      await this.updatePublicSettings(payload)
    },
  },
}
</script>
